import React, { Component } from "react"
import { Link } from "gatsby"

// Styles
import Styles from "./Nav.module.styl"

// Components
import { Row, Cell } from "~layout/Layout"
import NavCanvas from "./NavCanvas"

class Nav extends Component {
    constructor(props) {
        super(props)

        let pathname = ""

        if (typeof window !== "undefined") {
            pathname = window.location.pathname
        }

        this.entries = [
            {
                id: 0,
                to: "/pourquoi-le-bon-site",
                title: "Pourquoi le bon site ?",
                isCurrentEntry: pathname.indexOf("/pourquoi-le-bon-site") > -1,
            },
            {
                id: 1,
                to: "/questions/1",
                title: "Tester votre projet",
                isCurrentEntry: pathname.indexOf("/questions") > -1,
            },
            {
                id: 2,
                to: "/sites",
                title: "Les 12 types de sites",
                isCurrentEntry:
                    pathname.indexOf("/sites") > -1 &&
                    (pathname === "/sites" || pathname === "/sites/"),
            },
            {
                id: 3,
                to: "/passer-a-l-action",
                title: "Passer à l'action",
                isCurrentEntry: pathname.indexOf("/passer-a-l-action") > -1,
            },
        ]

        // Refs
        this.navCanvas = React.createRef()
    }

    state = {
        activeID: -1,
    }

    renderMenu() {
        const MenuItems = this.entries.map((item, index) => {
            return (
                <li
                    key={`nav__item__${item.id}`}
                    className={`${Styles.NavPrimary__item} ${
                        this.state.activeID === item.id
                            ? Styles.NavPrimary__item__active
                            : ""
                    }`}
                >
                    <Link
                        to={item.to}
                        className={`${Styles.NavPrimary__item__link} ${
                            this.state.activeID === item.id
                                ? Styles.NavPrimary__item__link__active
                                : ""
                        } nav__item`}
                        onMouseOver={() => {
                            this.setState({
                                activeID: item.id,
                            })

                            this.navCanvas.current.onMouseOver()
                        }}
                        onMouseOut={() => {
                            this.setState({
                                activeID: -1,
                            })

                            this.navCanvas.current.onMouseOut()
                        }}
                        onClick={(e) => {
                            if (item.isCurrentEntry) {
                                e.preventDefault()
                                this.props.closeNav()
                            }
                        }}
                    >
                        {item.title}
                    </Link>
                </li>
            )
        })

        return MenuItems
    }

    render() {
        const MenuItems = this.renderMenu()

        return (
            <nav
                className={`${Styles.NavPrimary} ${
                    this.props.isNavOpen ? Styles.NavPrimary__open : ""
                }`}
            >
                <NavCanvas isOpen={this.props.isNavOpen} ref={this.navCanvas} />
                <ul className={Styles.NavPrimary__inner}>{MenuItems}</ul>
            </nav>
        )
    }
}

export default Nav
