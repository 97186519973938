import React, { Component } from "react"

import { Link } from "gatsby"

// Styles
import Styles from "./Footer.module.styl"

// Components
import { Nav } from "~layout/Layout"
import { Row, Cell } from "~layout/Layout"
import LinkStyles from "~components/Links/LinkPrimary.module.styl"

// Logo
import Logo from "~images/logo-spintank.svg"

class Footer extends Component {
    constructor(props) {
        super(props)
    }

    render() {
        return (
            <footer className={Styles.Footer}>
                <Row>
                    <Cell start="0" end="4">
                        <div className={Styles.Footer__top__item}>
                            <div
                                className={`${Styles.Footer__title} heading-3`}
                            >
                                Vous avez un projet ?
                            </div>
                        </div>
                    </Cell>
                    <Cell start="5" end="11">
                        <div className={Styles.Footer__top__item}>
                            <p className={Styles.Footer__text}>
                                Faut-il attendre d'avoir un brief finalisé avant
                                de discuter avec une agence ? Pas forcément. Nos
                                experts sont disponibles pour échanger sur votre
                                projet quel que soit son état d'avancement,
                                qu'il s'agisse d'une envie de changement ou
                                d'une compétition déjà calée. N'hésitez
                                pas&nbsp;:
                            </p>
                            <a
                                href="mailto:hello@spintank.fr"
                                rel="noopeneer"
                                className={`${LinkStyles.LinkPrimary} link__item ${Styles.BoxSite__link}`}
                                title="hello@spintank.fr"
                            >
                                <span className={LinkStyles.LinkPrimary__label}>
                                    hello@spintank.fr
                                </span>
                                <div
                                    className={`${LinkStyles.LinkPrimary__icon}`}
                                ></div>
                            </a>
                        </div>
                    </Cell>
                </Row>
                <Row>
                    <Cell start="0" end="12">
                        <div className={Styles.Footer__bottom}>
                            <div className={Styles.Footer__lists}>
                                <ul className={`${Styles.Footer__list}`}>
                                    <li
                                        className={`${Styles.Footer__list__item}`}
                                    >
                                        <Link to="/notre-methode">
                                            Notre méthode
                                        </Link>
                                    </li>
                                    <li
                                        className={`${Styles.Footer__list__item}`}
                                    >
                                        <Link to="/mentions-legales">
                                            Mentions légales
                                        </Link>
                                    </li>
                                    <li
                                        className={`${Styles.Footer__list__item}`}
                                    >
                                        <a
                                            href="https://twitter.com/spintank"
                                            target="_blank"
                                            rel="noopener"
                                        >
                                            Twitter
                                        </a>
                                    </li>
                                    <li
                                        className={`${Styles.Footer__list__item}`}
                                    >
                                        <a
                                            href="https://fr-fr.facebook.com/spintank/"
                                            target="_blank"
                                            rel="noopener"
                                        >
                                            Facebook
                                        </a>
                                    </li>
                                    <li
                                        className={`${Styles.Footer__list__item__right}`}
                                    >
                                        <a
                                            href="https://www.spintank.fr"
                                            target="_blank"
                                            rel="noopener"
                                        >
                                            <img src={Logo} alt="Spintank" />
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </Cell>
                </Row>
            </footer>
        )
    }
}

export default Footer
