import React from "react"

class Panel extends React.Component {
    constructor(props) {
        super(props)
    }

    render() {
        return (
            <section
                className={`panel ${
                    this.props.size ? `panel--${this.props.size}` : ""
                } ${this.props.name ? `panel--${this.props.name}` : ""}`}
            >
                <div className="panel__inner">{this.props.children}</div>
            </section>
        )
    }
}

export default Panel
