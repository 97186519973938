import React from "react"

export default (props) => (
    <div
        className={`cell-start-${props.start} cell-end-${props.end} ${
            props.startD ? "cell-start-" + props.startD + "-d" : ""
        } ${props.endD ? "cell-end-" + props.endD + "-d" : ""} ${
            props.startTl ? "cell-start-" + props.startTl + "-tl" : ""
        } ${props.endTl ? "cell-end-" + props.endTl + "-tl" : ""} ${
            props.startTp ? "cell-start-" + props.startTp + "-tp" : ""
        } ${props.endTp ? "cell-end-" + props.endTp + "-tp" : ""}`}
    >
        {props.children}
    </div>
)
