import React from "react"

import SVGSpriteStyles from "./SVGSprite.module.styl"

const SVGSprite = () => {
    return (
        <svg className={SVGSpriteStyles.SVGSprite}>
            <defs>
                {/* 48 */}
                <symbol id="icon-burger" viewBox="0 0 48 48">
                    <g fillRule="evenodd">
                        <path
                            fillRule="nonzero"
                            d="M24,0 C37.254834,0 48,10.745166 48,24 C48,37.254834 37.254834,48 24,48 C10.745166,48 0,37.254834 0,24 C0,10.745166 10.745166,0 24,0 Z M24,1 C11.2974508,1 1,11.2974508 1,24 C1,36.7025492 11.2974508,47 24,47 C36.7025492,47 47,36.7025492 47,24 C47,11.2974508 36.7025492,1 24,1 Z"
                        />
                        <path
                            d="M12,10 L12,12 L0,12 L0,10 L12,10 Z M19,10 L19,12 L14,12 L14,10 L19,10 Z M5,5 L5,7 L0,7 L0,5 L5,5 Z M19,5 L19,7 L7,7 L7,5 L19,5 Z M12,0 L12,2 L0,2 L0,0 L12,0 Z M19,0 L19,2 L14,2 L14,0 L19,0 Z"
                            transform="translate(14 18)"
                        />
                    </g>
                </symbol>

                <symbol id="icon-burger-close" viewBox="0 0 48 48">
                    <g fillRule="evenodd">
                        <path
                            fillRule="nonzero"
                            d="M24,0 C37.254834,0 48,10.745166 48,24 C48,37.254834 37.254834,48 24,48 C10.745166,48 0,37.254834 0,24 C0,10.745166 10.745166,0 24,0 Z M24,1 C11.2974508,1 1,11.2974508 1,24 C1,36.7025492 11.2974508,47 24,47 C36.7025492,47 47,36.7025492 47,24 C47,11.2974508 36.7025492,1 24,1 Z"
                        />
                        <path
                            d="M13.5104076,0.575378798 L14.9246212,1.98959236 L8.9143788,8.0003788 L14.9246212,14.0104076 L13.5104076,15.4246212 L7.5003788,9.4143788 L1.48959236,15.4246212 L0.0753787975,14.0104076 L6.0863788,8.0003788 L0.0753787975,1.98959236 L1.48959236,0.575378798 L7.5003788,6.5863788 L13.5104076,0.575378798 Z"
                            transform="translate(17 16)"
                        />
                    </g>
                </symbol>

                <symbol id="icon-big-arrow-right" viewBox="0 0 48 48">
                    <path
                        fillRule="evenodd"
                        d="M25.6199846,0 L48,24 L25.6199846,48 L24.9325606,47.2628166 L46.138,24.521 L0,24.5212674 L0,23.4787326 L46.139,23.478 L24.9325606,0.737183394 L25.6199846,0 Z"
                    />
                </symbol>

                {/* 32 */}
                <symbol id="icon-round-arrow-right" viewBox="0 0 32 32">
                    <g fillRule="evenodd">
                        <path
                            fillRule="nonzero"
                            d="M16,0 C24.836556,0 32,7.163444 32,16 C32,24.836556 24.836556,32 16,32 C7.163444,32 0,24.836556 0,16 C0,7.163444 7.163444,0 16,0 Z M16,1 C7.71572875,1 1,7.71572875 1,16 C1,24.2842712 7.71572875,31 16,31 C24.2842712,31 31,24.2842712 31,16 C31,7.71572875 24.2842712,1 16,1 Z"
                        />
                        <path
                            d="M10.3641,0.65731765 L18.6866246,9.5 L10.3641,18.3426823 L9.6359,17.6573177 L16.842,9.99931765 L0,10 L0,9 L16.842,8.99931765 L9.6359,1.34268235 L10.3641,0.65731765 Z"
                            transform="translate(7 7)"
                        />
                    </g>
                </symbol>

                <symbol id="icon-plus" viewBox="0 0 32 32">
                    <polygon points="16.941 0 16.941 15.059 32 15.059 32 16.941 16.941 16.941 16.941 32 15.059 32 15.059 16.941 0 16.941 0 15.059 15.059 15.059 15.059 0" />
                </symbol>

                <symbol id="icon-minus" viewBox="0 0 32 32">
                    <rect width="32" height="2" y="15" fillRule="evenodd" />
                </symbol>

                {/* 16 */}
                <symbol id="icon-drag" viewBox="0 0 16 16">
                    <path
                        fillRule="evenodd"
                        d="M16,13 L16,15 L0,15 L0,13 L16,13 Z M16,7 L16,9 L0,9 L0,7 L16,7 Z M16,1 L16,3 L0,3 L0,1 L16,1 Z"
                    />
                </symbol>

                <symbol id="icon-drag-mobile" viewBox="0 0 16 16">
                    <path d="M12,10 L8.5,14 L5,10 L12,10 Z M8.5,2 L12,6 L5,6 L8.5,2 Z M8,6 L9,6 L9,10 L8,10 L8,6 Z"></path>
                </symbol>

                {/* 144 */}
                <symbol id="icon-success" viewBox="0 0 144 144">
                    <g fill="none" fillRule="evenodd">
                        <circle
                            cx="72"
                            cy="72"
                            r="71"
                            stroke="#000"
                            strokeWidth="2"
                        />
                        <polygon
                            fill="#000"
                            fillRule="nonzero"
                            points="114.293 48.664 115.707 50.078 64.025 101.761 39.299 78.732 40.662 77.268 63.974 98.981"
                        />
                    </g>
                </symbol>
            </defs>
        </svg>
    )
}
export default SVGSprite
