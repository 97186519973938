import React, { Component } from "react"

// Styles
import Styles from "./Nav.module.styl"

// Helpers
import NumberUtils from "~helpers/numberUtils"

// Libs
const random = require("canvas-sketch-util/random")

class NavCanvas extends Component {
    constructor(props) {
        super(props)

        // ES6 rebind
        this.draw = this.draw.bind(this)
        this.onMouseMove = this.onMouseMove.bind(this)
        this.onResize = this.onResize.bind(this)

        // Refs
        this.canvas = React.createRef()
        this.canvasWrapper = React.createRef()
    }

    state = {
        hasStartedDrawing: false,
    }

    componentWillMount() {
        this.mouse = {
            x: 0,
            y: 0,
        }

        this.pos = {
            x: 0,
            y: 0,
        }

        this.alpha = 0
        this.alphaTransition = 0

        this.scaleShape = 0.5
        this.scaleShapeTransition = 1

        this.isOver = false
    }

    componentDidMount() {
        console.log("didMount NavCanvas")

        this.bindEvents()
        this.createCanvas()
        this.setCanvasSizes()
        this.draw()
    }

    componentWillUnmount() {
        this.unbindEvents()
        this.undraw()
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (
            !prevProps.isOpen &&
            this.props.isOpen &&
            !this.state.hasStartedDrawing
        ) {
            this.setState({
                hasStartedDrawing: true,
            })
        }
    }

    bindEvents() {
        document.addEventListener("mousemove", this.onMouseMove)
        window.addEventListener("resize", this.onResize)
    }

    unbindEvents() {
        document.removeEventListener("mousemove", this.onMouseMove)
        window.removeEventListener("resize", this.onResize)
    }

    onResize() {
        this.setCanvasSizes()
    }

    createCanvas() {
        this.ratio = window.devicePixelRatio
        this.ctx = this.canvas.current.getContext("2d")
    }

    updatePos() {
        this.pos.x = NumberUtils.lerp(this.pos.x, this.mouse.x, 0.05)
        this.pos.y = NumberUtils.lerp(this.pos.y, this.mouse.y, 0.05)

        this.alphaTransition = NumberUtils.lerp(
            this.alphaTransition,
            this.alpha,
            0.3
        )
        this.scaleShapeTransition = NumberUtils.lerp(
            this.scaleShapeTransition,
            this.scaleShape,
            0.06
        )
    }

    setCanvasSizes() {
        this.canvas.current.width =
            this.canvasWrapper.current.getBoundingClientRect().width *
            this.ratio
        this.canvas.current.height =
            this.canvasWrapper.current.getBoundingClientRect().height *
            this.ratio

        this.canvas.current.style.width = `${
            this.canvasWrapper.current.getBoundingClientRect().width
        }px`
        this.canvas.current.style.height = `${
            this.canvasWrapper.current.getBoundingClientRect().height
        }px`

        this.width = this.canvas.current.width / this.ratio
        this.height = this.canvas.current.height / this.ratio

        this.pos.x = this.width / 2
        this.pos.y = this.height / 2
    }

    clearSketch() {
        this.ctx.fillStyle = "rgba(0,0,0,0)"
        this.ctx.clearRect(0, 0, this.width, this.height)
        this.ctx.fillRect(0, 0, this.width, this.height)
    }

    onResize() {
        this.setCanvasSizes()
    }

    onMouseMove(e) {
        this.mouse.x = e.pageX
        this.mouse.y = e.pageY - window.scrollY
    }

    onMouseOver() {
        this.alpha = 1
        this.scaleShape = 1
        this.isOver = true
    }

    onMouseOut() {
        this.alpha = 0
        this.scaleShape = 0.5
        this.isOver = false
    }

    undraw() {
        this.setState({
            hasStartedDrawing: false,
        })

        cancelAnimationFrame(this.raf)
        this.raf = null
    }

    draw() {
        this.raf = requestAnimationFrame(this.draw.bind(this))

        if (!this.props.isOpen || !this.state.hasStartedDrawing) {
            return
        }

        this.updatePos()

        const time = Date.now() / 2000

        this.ctx.save()
        // SCALE RATIO FOR DEVICE
        this.ctx.scale(this.ratio, this.ratio)
        this.clearSketch()

        const totalLines = 100
        //DRAW THE SHAPE
        this.ctx.save()
        this.ctx.translate(this.pos.x, this.pos.y)
        this.ctx.scale(this.scaleShapeTransition, this.scaleShapeTransition)

        for (let i = 0; i < totalLines; i++) {
            this.ctx.beginPath()
            const angle = NumberUtils.mapRange(i, 0, totalLines, 0, 2 * Math.PI)

            const x1 = Math.cos(angle) * (180 * this.scaleShapeTransition)
            const y1 = Math.sin(angle) * (180 * this.scaleShapeTransition)

            //CALC OF NOISE VALUE
            const nx = x1 / this.width - 0.5
            const ny = y1 / this.height - 0.5
            let n =
                1.5 * random.noise2D(1.6 * nx, 3.6 * ny + time) +
                0.5 * random.noise2D(2 * nx, 2 * ny + time) +
                0.25 * random.noise2D(4 * nx, 2 * ny + time)

            //SIZE OF BLOB LINES (VARYING BETWEEN 250 & 300)
            const scalar = NumberUtils.mapRange(n, -1, 1, 25, 50)

            const x2 = Math.cos(angle) * (180 + scalar)
            const y2 = Math.sin(angle) * (180 + scalar)

            //DRAW EACH LINE OF THE BLOB
            this.ctx.moveTo(x1, y1)
            this.ctx.lineTo(x1, y1)
            this.ctx.lineTo(x2, y2)
            this.ctx.closePath()
            this.ctx.strokeStyle = `rgba(0,0,0,${this.alphaTransition})`
            this.ctx.stroke()
        }

        this.ctx.restore()
        this.ctx.restore()
    }

    render() {
        return (
            <div className={Styles.NavPrimary__canvas} ref={this.canvasWrapper}>
                <canvas
                    className={Styles.NavPrimary__canvas__item}
                    ref={this.canvas}
                ></canvas>
            </div>
        )
    }
}

export default NavCanvas
