import React, { Component } from "react"

// Components
import { Header } from "~layout/Layout"
import SVGSprite from "~icons/SVGSprite"
import Helmet from "react-helmet"

// Images
import Meta from "~images/meta.jpg"

// Libs
// import Klaro without CSS
const Klaro = typeof window !== `undefined` ? require("klaro") : null

// we define a minimal configuration
const config = {
    lang: "fr",
    acceptAll: true,
    translations: {
        fr: {
            acceptSelected: "Accepter sélectionné(s)",
            acceptAll: "Tout accepter",
            ok: "J'accepte",
            consentNotice: {
                learnMore: "Personnaliser",
                description:
                    "Bonjour ! Pourrions-nous activer des services supplémentaires à des fins {purposes} ? Vous pouvez toujours modifier ou retirer votre consentement plus tard.",
            },
            googleAnalytics: {
                title: "Google Analytics",
                description:
                    "Utilisé pour récupérer les statistiques d’utilisation du site, tout reste anonyme et on ne transmet ces données à personne - sauf Google, évidemment.",
            },
            hotjar: {
                title: "HotJar",
                description:
                    "Utilisé pour suivre plus en détail l’utilisation des fonctionnalités et pages du site, afin de pouvoir optimiser l’expérience pour le rendre plus agréable à utiliser.",
            },
            purposes: {
                analytics: "Statistiques",
            },
        },
    },
    apps: [
        {
            name: "googleAnalytics",
            purposes: ["analytics"],
        },
        {
            name: "hotjar",
            purposes: ["analytics"],
        },
    ],
}

if (typeof window !== "undefined") {
    // we assign the Klaro module to the window, so that we can access it in JS
    window.klaro = Klaro
    window.klaroConfig = config
    // we set up Klaro with the config
    Klaro.setup(config)
}

class View extends Component {
    constructor(props) {
        super(props)

        // ES6 Rebind
        this.setCorrectVH = this.setCorrectVH.bind(this)

        if (typeof window !== "undefined") {
            this.setCorrectVH()
            this.bindEvents()
        }
    }

    componentWillUnmount() {
        this.unbindEvents()
    }

    setCorrectVH() {
        let vh = window.innerHeight * 0.01
        document.documentElement.style.setProperty("--vh", `${vh}px`)
    }

    bindEvents() {
        window.addEventListener("resize", this.setCorrectVH)
        window.addEventListener("orientationchange", this.setCorrectVH)
    }

    unbindEvents() {
        window.removeEventListener("resize", this.setCorrectVH)
        window.removeEventListener("orientationchange", this.setCorrectVH)
    }

    render() {
        return (
            <div
                className={`view view--${this.props.name} ${this.props.extraClassNames}`}
                data-theme={this.props.theme}
            >
                <Helmet
                    defaultTitle="Le bon site, par Spintank"
                    titleTemplate="%s - Le bon site, par Spintank"
                >
                    <html lang="fr" amp />
                    <meta property="og:image" content={Meta} />
                    <meta
                        name="twitter:title"
                        content="Le bon site, par Spintank"
                    />
                    <meta name="twitter:card" content="summary_large_image" />
                    <meta
                        name="twitter:image"
                        content={`https://lebonsite.spintank.fr${Meta}`}
                    />
                    <meta itemprop="image" content={Meta} />
                </Helmet>

                <Header />

                {/* <div className={'test'}>
                    <button onClick={(e) => {
                        const manager = window.klaro.getManager(window.klaroConfig);
                        console.log(manager);
                        manager.changeAll(true)
                    }}>J'accepte</button>
                    <button onClick={(e) => {
                        const manager = window.klaro.getManager(window.klaroConfig);
                        console.log(manager);
                        manager.changeAll(false)
                    }}>Je refuse</button>
                    <button onClick={(e) => {
                        window.klaro.show(window.klaroConfig)
                    }}>Personnaliser</button>
                </div> */}

                <div className="view__inner">{this.props.children}</div>
                <SVGSprite />
            </div>
        )
    }
}

export default View
