/*
 * Declaration of the global namespace sptk
 *  with 3 defaults namespace : utils, modules, plugins, libs
 */
class SptkUtils {
    constructor() {
        this.utils = {}

        this.initialize()
    }

    initialize() {
        if (typeof window != "undefined") {
            this.checkMobile()
            this.checkMobileTablet()
            this.checkTablet()
        }
    }

    checkMobile() {
        this.utils.isMobile =
            (window.navigator.userAgent.match(/Android/i) &&
                window.navigator.userAgent.indexOf("Mobile") > -1) ||
            window.navigator.userAgent.match(/iPhone/i) ||
            window.navigator.userAgent.match(/iPod/i) ||
            window.navigator.userAgent.match(/windows phone/i) ||
            window.navigator.userAgent.match(/BlackBerry/i) ||
            window.navigator.userAgent.match(/BB10/i)
                ? true
                : false
    }

    checkMobileTablet() {
        this.utils.isMobileTablet =
            window.navigator.userAgent.match(/Android/i) ||
            window.navigator.userAgent.match(/webOS/i) ||
            window.navigator.userAgent.match(/iPhone/i) ||
            window.navigator.userAgent.match(/iPod/i) ||
            window.navigator.userAgent.match(/iPad/i) ||
            window.navigator.userAgent.match(/windows phone/i) ||
            window.navigator.userAgent.match(/BlackBerry/i)
                ? true
                : false
    }

    checkTablet() {
        this.utils.isTablet =
            (window.navigator.userAgent.match(/Android/i) &&
                window.navigator.userAgent.indexOf("Mobile") === -1) ||
            window.navigator.userAgent.match(/webOS/i) ||
            window.navigator.userAgent.match(/iPad/i)
                ? true
                : false
    }
}

export default SptkUtils
