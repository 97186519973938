import React, { Component } from "react"

// Styles
import Styles from "./ButtonSecondary.module.styl"

class ButtonSecondary extends Component {
    constructor(props) {
        super(props)
    }

    render() {
        return (
            <a
                {...this.props.btnProps}
                className={`btn--secondary ${Styles.ButtonSecondary}`}
            >
                {this.props.label}
            </a>
        )
    }
}

export default ButtonSecondary
