import React, { Component } from "react"
import { Link } from "gatsby"

// Libs
import SptkUtils from "~helpers/sptk"
import {
    disableBodyScroll,
    enableBodyScroll,
    clearAllBodyScrollLocks,
} from "body-scroll-lock"

// Styles
import Styles from "./Header.module.styl"

// Components
import { Nav } from "~layout/Layout"
import { Row, Cell } from "~layout/Layout"
import ButtonSecondary from "~components/Buttons/ButtonSecondary"
import Icon from "~icons/Icon"

// Images
import Logo from "~images/logo-spintank.svg"

class Header extends Component {
    constructor(props) {
        super(props)

        // Helpers
        this.device = new SptkUtils()

        // Refs
        this.header = React.createRef()
        this.lineLeftTop = React.createRef()
        this.lineLeftBottom = React.createRef()
        this.lineRightTop = React.createRef()
        this.lineRightBottom = React.createRef()
        this.burgerBtn = React.createRef()

        // ES6 Rebind
        this.onScroll = this.onScroll.bind(this)
        this.closeNav = this.closeNav.bind(this)
    }

    state = {
        isNavOpen: false,
        isScrolled: false,
    }

    componentDidMount() {
        if (this.device.utils.isMobile) {
            this.bindEvents()

            this.burgerBtn.current.focus({
                preventScroll: true,
            })
        }
    }

    componentWillUnmount() {
        if (this.device.utils.isMobile) {
            this.unbindEvents()
        }

        clearAllBodyScrollLocks()
    }

    bindEvents() {
        if (this.device.utils.isMobile) {
            window.addEventListener("scroll", this.onScroll)
        }
    }

    unbindEvents() {
        if (this.device.utils.isMobile) {
            window.removeEventListener("scroll", this.onScroll)
        }
    }

    onScroll(e) {
        if (!this.header || this.header.current === null) {
            return
        }

        const doc = document.documentElement
        const scrollY =
            (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0)
        const headerHeight = this.header.current.getBoundingClientRect().height

        if (scrollY >= headerHeight) {
            if (!this.state.isScrolled) {
                this.setState({
                    isScrolled: true,
                })
            }
        } else {
            if (this.state.isScrolled) {
                this.setState({
                    isScrolled: false,
                })
            }
        }
    }

    onClickBurger() {
        this.setState(
            {
                isNavOpen: !this.state.isNavOpen,
            },
            () => {
                let event

                if (this.state.isNavOpen) {
                    event = new CustomEvent("nav:open")
                    disableBodyScroll(document.body)
                } else {
                    event = new CustomEvent("nav:close")
                    enableBodyScroll(document.body)
                }

                document.dispatchEvent(event)
            }
        )

        this.animBurger()
    }

    closeNav() {
        this.onClickBurger()
    }

    animBurger() {
        if (this.state.isNavOpen === false) {
            this.burgerBtn.current.classList.add("burger__open")
            this.lineLeftTop.current.classList.add("burger__left__open")
            this.lineLeftBottom.current.classList.add("burger__left__open")
            this.lineRightTop.current.classList.add("burger__right-top__open")
            this.lineRightBottom.current.classList.add(
                "burger__right-bottom__open"
            )
        } else {
            this.burgerBtn.current.classList.remove("burger__open")
            this.lineLeftTop.current.classList.remove("burger__left__open")
            this.lineLeftBottom.current.classList.remove("burger__left__open")
            this.lineRightTop.current.classList.remove(
                "burger__right-top__open"
            )
            this.lineRightBottom.current.classList.remove(
                "burger__right-bottom__open"
            )
        }
    }

    render() {
        return (
            <>
                <header
                    className={`${Styles.Header} ${
                        this.state.isNavOpen ? Styles.Header__open : ""
                    } ${
                        this.state.isScrolled ? Styles.Header__is_scrolled : ""
                    }`}
                    ref={this.header}
                >
                    <Row>
                        <Cell start="0" end="12">
                            <div className={Styles.Header__inner}>
                                <div className={Styles.Header__left}>
                                    <button
                                        className={Styles.Header__burger}
                                        ref={this.burgerBtn}
                                        aria-label={`${
                                            this.state.isNavOpen
                                                ? "Fermer le menu"
                                                : "Ouvrir le menu"
                                        }`}
                                        onClick={() => {
                                            this.onClickBurger()
                                        }}
                                    >
                                        <svg
                                            preserveAspectRatio="xMidYMid meet"
                                            width="100%"
                                            height="100%"
                                            viewBox="0 0 21 13"
                                        >
                                            <g
                                                stroke="none"
                                                strokeWidth="1"
                                                fill="none"
                                                fillRule="evenodd"
                                                strokeLinecap="square"
                                            >
                                                <g
                                                    transform="translate(1.000000, 1.000000)"
                                                    stroke="#000000"
                                                    strokeWidth="2"
                                                >
                                                    <line
                                                        className={
                                                            Styles.Header__burger__left
                                                        }
                                                        ref={this.lineLeftTop}
                                                        x1="0.5"
                                                        y1="0.5"
                                                        x2="18.5"
                                                        y2="0.5"
                                                        id="Line-3"
                                                    ></line>
                                                    <line
                                                        className={
                                                            Styles.Header__burger__right
                                                        }
                                                        ref={this.lineRightTop}
                                                        x1="0.5"
                                                        y1="5.5"
                                                        x2="18.5"
                                                        y2="5.5"
                                                        id="Line-3-Copy"
                                                    ></line>
                                                    <line
                                                        className={
                                                            Styles.Header__burger__right
                                                        }
                                                        ref={
                                                            this.lineRightBottom
                                                        }
                                                        x1="0.5"
                                                        y1="5.5"
                                                        x2="18.5"
                                                        y2="5.5"
                                                        id="Line-3-Copy"
                                                    ></line>
                                                    <line
                                                        className={
                                                            Styles.Header__burger__left
                                                        }
                                                        ref={
                                                            this.lineLeftBottom
                                                        }
                                                        x1="0.5"
                                                        y1="10.5"
                                                        x2="18.5"
                                                        y2="10.5"
                                                        id="Line-3-Copy-2"
                                                    ></line>
                                                </g>
                                            </g>
                                        </svg>
                                    </button>

                                    <Link
                                        to="/"
                                        className={Styles.Header__logo}
                                        aria-label={
                                            "Accéder à la page d'accueil"
                                        }
                                        title={"Accéder à la page d'accueil"}
                                    >
                                        <img src={Logo} alt="Logo" />
                                    </Link>
                                </div>

                                {/*<div className={Styles.Header__right}>
                                    <ButtonSecondary label="Contactez-nous" btnProps={{href: 'https://www.spintank.fr', target: '_blank', rel: 'noopener'}} />
                                </div>*/}
                            </div>
                        </Cell>
                    </Row>
                </header>

                <Nav
                    isNavOpen={this.state.isNavOpen}
                    closeNav={this.closeNav}
                />
            </>
        )
    }
}

export default Header
