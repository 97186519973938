const EPSILON = Number.EPSILON

const NumberUtils = {
    lerp(start, end, amt) {
        return (1 - amt) * start + amt * end
    },

    distance(x1, y1, x2, y2) {
        return Math.sqrt(Math.pow(x1 - x2, 2) + Math.pow(y1 - y2, 2))
    },

    mapRange(value, inputMin, inputMax, outputMin, outputMax, clamp) {
        // Reference:
        // https://openframeworks.cc/documentation/math/ofMath/
        if (Math.abs(inputMin - inputMax) < EPSILON) {
            return outputMin
        } else {
            var outVal =
                ((value - inputMin) / (inputMax - inputMin)) *
                    (outputMax - outputMin) +
                outputMin

            if (clamp) {
                if (outputMax < outputMin) {
                    if (outVal < outputMax) outVal = outputMax
                    else if (outVal > outputMin) outVal = outputMin
                } else {
                    if (outVal > outputMax) outVal = outputMax
                    else if (outVal < outputMin) outVal = outputMin
                }
            }

            return outVal
        }
    },

    clamp(value, min, max) {
        return min < max
            ? value < min
                ? min
                : value > max
                ? max
                : value
            : value < max
            ? max
            : value > min
            ? min
            : value
    },

    calcHypotenuse(a, b) {
        return Math.sqrt(a * a + b * b)
    },

    radians_to_degrees(radians) {
        var pi = Math.PI
        return radians * (180 / pi)
    },
}

export default NumberUtils
