import React from "react"
import PropTypes from "prop-types"

const Icon = (props) => {
    const { name, extraClasses, width, height, fill, stroke } = props

    return (
        <svg
            className={`icon icon--${name}${
                extraClasses ? ` ${extraClasses}` : ""
            }`}
            width={width}
            height={height}
        >
            <use
                xlinkHref={`#icon-${name}`}
                fill={fill ? fill : ""}
                stroke={stroke ? stroke : ""}
            ></use>
        </svg>
    )
}

Icon.propTypes = {
    name: PropTypes.string.isRequired,
    width: PropTypes.number.isRequired,
    height: PropTypes.number.isRequired,
    fill: PropTypes.string,
    stroke: PropTypes.string,
    extraClasses: PropTypes.string,
}

export default Icon
